
<template>
  <div class="app-container">
    <el-date-picker
      v-model="timeValue"
      type="daterange"
      start-placeholder="起始时间"
      end-placeholder="结束时间"
      :default-time="defaultTime"
      format="YYYY-MM-DD"
      value-format="YYYY-MM-DD"
      size="small"
    />
    <el-button
      size="mini"
      type="primary"
      @click="selectdata"
    >
      {{ "查询" }}
    </el-button>
    <el-button
      size="mini"
      type="primary"
      @click="resetbtn"
    >
      {{ "重置" }}
    </el-button>
    <el-button
      style="float:right"
      size="mini"
      type="primary"
      @click="setattendrule"
    >
      {{ "设置规则" }}
    </el-button>
    <el-button
      style="float:right"
      size="mini"
      type="primary"
      @click="exportExcel"
    >
      {{ "导出EXCEL" }}
    </el-button>
    <el-tabs
      v-model="activeName"
      class="demo-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="考勤记录"
        name="first"
      >
        <el-table
          :data="rolesList"
          style="width:100%;margin-top:30px;"
          border
        >
          <el-table-column
            align="center"
            label="姓名"
          >
            <template #default="{row}">
              {{ row.username }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="部门"
          >
            <template #default="{row}">
              {{ row.dept }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="日期"
          >
            <template #default="{row}">
              {{ row.qiandao }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="签到时间"
          >
            <template #default="{row}">
              {{ row.qiandaotime }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="迟到分钟"
          >
            <template #default="{row}">
              {{ row.chidao!== null ? row.chidao: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="迟到次数"
          >
            <template #default="{row}">
              {{ row.chidaocishu !== null ? row.chidaocishu: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="签退时间"
          >
            <template #default="{row}">
              {{ row.qiantui }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="早退分钟"
          >
            <template #default="{row}">
              {{ row.zaotui!== null ? row.zaotui: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="早退次数"
          >
            <template #default="{row}">
              {{ row.zaotuicishu !== null ? row.zaotuicishu: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="矿工天数"
          >
            <template #default="{row}">
              {{ row.kuanggong!== null ? row.kuanggong: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="实到天数"
          >
            <template #default="{row}">
              {{ row.shidao!== null ? row.shidao: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="应到天数"
          >
            <template #default="{row}">
              {{ row.yingdao!== null ? row.yingdao: 0 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="工时"
          >
            <template #default="{row}">
              {{ row.gongshi!== null ? row.gongshi: 0 }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane
        label="考勤规则"
        name="second"
      >
        <el-table
          :data="ruledata"
          style="width:100%;margin-top:30px;"
          border
        >
          <el-table-column
            align="center"
            label="考勤名称"
          >
            <template #default="{row}">
              {{ row.rule_name }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="上班打卡时间"
          >
            <template #default="{row}">
              {{ row.rule_work_time }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="下班打卡时间"
          >
            <template #default="{row}">
              {{ row.rule_go_home_time }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="超时时间"
          >
            <template #default="{row}">
              {{ row.rule_late_time }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="单休日期"
          >
            <template #default="{row}">
              {{ row.rule_rest_week_days === '6' ? '周六' : row.rule_rest_week_days === '7'?'周日':'双休' }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-model="dialogVisible"
      width="22rem"
      :title="dialogType"
    >
      <el-form
        :model="rule"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="考勤名称">
          <el-input
            size="mini"
            v-model="rule.rulename"
            placeholder="请输入考勤名称"
          />
        </el-form-item>
        <el-form-item label="可迟到时间">
          <el-input
            size="mini"
            v-model="rule.latetime"
            placeholder="请输入时间"
          />
        </el-form-item>
        <el-form-item
          label="休息时间"
        >
          <el-select
            v-model="rule.resttime"
            class="m-2"
            placeholder="请选择休息时间"
            size="mini"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="上班时间">
          <el-time-picker
            v-model="rule.workhourson"
            placeholder="上班打卡时间"
          />
        </el-form-item>
        <el-form-item label="下班时间">
          <el-time-picker
            v-model="rule.workhoursoff"
            placeholder="下班打卡时间"
          />
        </el-form-item>
      </el-form>
      <div
        style="text-align:center;"
      >
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleUpdate()"
        >
          {{ "确认" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { attendanceRecord, attendanceExcel, attendanceRuleSet, attendanceRuleAll } from '@/apis/user'
import { parseTime } from '@/utils/index'
import { ElMessage } from 'element-plus'
interface Rule {
    rulename: string
    resttime: string
    latetime: number
    workhourson: string
    workhoursoff: string
}
const defaultRole: Rule = {
  rulename: '',
  resttime: '',
  latetime: 5,
  workhourson: '',
  workhoursoff: ''
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const state = reactive({
      timeValue: '',
      dialogType: '',
      defaultTime: [
        parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime())),
        parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1))
      ],
      ruledata: [] as any,
      activeName: 'first',
      rolesList: [] as any,
      downurl: '' as any,
      dialogVisible: false,
      rule: Object.assign({}, defaultRole),
      options: [
        {
          value: '6',
          label: '周六单休'
        },
        {
          value: '2',
          label: '周日单休'
        },
        {
          value: '6,7',
          label: '双休'
        }
      ],
      exportExcel: async() => {
        console.log(parseTime(state.timeValue[0]), state.timeValue[1])
        await attendanceExcel({ dlsardate: parseTime(state.timeValue[0]), dlstpdate: parseTime(state.timeValue[1]) }).then(res => {
          console.log(res?.url)
          state.downurl = res?.url
          if (res?.url) window.location.href = res.url
          else {
            throw new Error(res?.msg)
          }
        })
      },
      handProgress: async(event: any) => {
        console.log(event)
      },
      getAllUser: async(startTime?: string, endTime?: string) => {
        if (startTime && endTime) {
          await attendanceRecord({ startdate: parseTime(startTime), stopdate: parseTime(endTime) }).then((res) => {
            state.rolesList = res?.data
          })
        } else {
          await attendanceRecord({ startdate: parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime())), stopdate: parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)) }).then((res) => {
            state.rolesList = res?.data
          })
        }
      },
      getAllRule: async() => {
        await attendanceRuleAll().then((res) => {
          state.ruledata = res?.data
        })
      },
      selectdata: async() => {
        (state.timeValue[0] && state.timeValue[1]) && state.getAllUser(state.timeValue[0], state.timeValue[1])
      },
      setattendrule: () => {
        state.dialogType = '添加规则'
        state.dialogVisible = true
      },
      resetbtn: () => {
        state.getAllUser()
      },
      checkUser: (scope: any) => {
        console.log(scope)
        router.push({
          path: 'user'
        })
      },
      changeState: (scope: any) => {
        console.log(scope)
      },
      handleUpdate: async() => {
        await attendanceRuleSet({ ...state.rule }).then((res: any) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: res?.msg
            })
            state.dialogVisible = false
          } else {
            ElMessage({
              type: 'error',
              message: res?.msg
            })
          }
        })
      },
      handleClick: async() => {
        switch (state.activeName) {
          case 'first' : state.getAllUser()
            break
          case 'second' : state.getAllRule()
            break
        }
      }
    })
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUser()
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }
 .editor-slide-upload {
  .el-upload--picture-card {
    width: 100%;
  }
}
  .uploadImag{
      width: 200px;
      height: 100px;
      background-color: green;
  }
}
</style>

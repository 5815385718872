<!--
 * @Description: 角色切换和菜单配置（后端控制权限使用）
 * @Author: ZY
 * @Date: 2021-01-08 19:32:16
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-13 16:49:21
-->
<template>
  <div class="app-container">
    <!-- <el-button
      size="mini"
      type="primary"
      @click="handleCreateRole"
    >
      {{ t("permission.createRole") }}
    </el-button> -->

    <el-table
      :data="rolesList"
      style="width:100%;margin-top:30px;"
      border
    >
      <el-table-column
        align="center"
        label="角色名"
      >
        <template #default="{row}">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态"
      >
        <template #default="{row}">
          {{ row.eid === 0 ?"启用" : "禁用" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="Operations"
      >
        <template #default="scope">
          <el-button
            type="danger"
            size="mini"
            @click="handleUpdate(scope)"
          >
            {{ scope.row.eid === 0 ? "禁用":"启用" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-dialog
      v-model="dialogVisible"
      width="22rem"
      :title="dialogType === 'edit' ? '修改账户' : '添加账户'"
    >
      <el-form
        :model="role"
        label-width="60px"
        label-position="left"
      >
        <el-form-item label="企业">
          <el-input
            size="mini"
            v-model="role.realname"
            placeholder="请输入企业名称"
          />
        </el-form-item>
        <el-form-item label="账户">
          <el-input
            size="mini"
            v-model="role.username"
            placeholder="账户"
          />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            size="mini"
            v-model="role.password"
            placeholder="密码"
          />
        </el-form-item>
        <el-form-item label="等级">
          <el-input
            size="mini"
            v-model="role.userrole"
            placeholder="等级"
          />
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="upload-demo"
            action="https://ai.faceswap.bainiankeji.com/manageusers/"
            :file-list="fileList"
            name="faceimg"
            :data="{...role}"
            :on-success="handleSuccess"
            :on-progress="handProgress"
            :on-change="imgSaveToUrl"
            :show-file-list="true"
          >
            <el-button type="primary">
              上传图片
            </el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="部门id">
          <el-input
            size="mini"
            v-model="role.userdept"
            placeholder="部门id"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:center;">
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ t("permission.cancel") }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="addManage"
        >
          {{ t("permission.confirm") }}
        </el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, toRefs, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'
import { allRoles, updateStatus, addManage } from '@/apis/user'
import { ElMessage } from 'element-plus'
interface Role {
    username: string|number
    password: string
    realname: string
    userdept: string|number
    userrole: string|number
    faceimg: any
}
const defaultRole: Role = {
  username: '',
  password: '',
  realname: '我的',
  userdept: '',
  userrole: '',
  faceimg: ''
}

export default defineComponent({
  setup() {
    const form = document.getElementById('pic')
    const imageFrontUrl = ref('')
    const { ctx } = getCurrentInstance() as any
    const router = useRouter()
    const state = reactive({
      rolesList: [] as any,
      dialogVisible: false,
      dialogType: 'new',
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      role: Object.assign({}, defaultRole),
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
      },
      addManage: async() => {
        console.log(form)
        await addManage(state.role).then((res: any) => {
          console.log(res)
        })
      },
      handProgress: async(event: any) => {
        console.log(event)
      },
      getAllUser: async() => {
        await allRoles().then((res) => {
          state.rolesList = res?.data
        })
      },
      handleUpdate: (scope: any) => {
        const { row } = scope
        updateStatus({ eid: row.eid === 0 ? 1 : 0, rid: row.rid }).then((res: any) => {
          ctx.$message({
            type: 'success',
            message: res?.msg
          })
          state.getAllUser()
        })
      },
      checkUser: (scope: any) => {
        console.log(scope)
        router.push({
          path: 'user'
        })
      },
      changeState: (scope: any) => {
        console.log(scope)
      },
      handleSuccess: () => {
        state.dialogVisible = false
        state.role = {
          username: '',
          password: '',
          realname: '',
          userdept: '',
          userrole: '',
          faceimg: ''
        }
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imgSaveToUrl = (file: any) => {
      console.log(file)
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
      }
    }
    // const getServiceRoutes = () => {
    //   getRoutes().then((res) => {
    //     state.serviceRoutes = res?.data.routes as any as RouteRecordRaw[]
    //     state.reshapedRoutes = getReshapeRoutes(res?.data.routes as any as RouteRecordRaw[])
    //   })
    // }
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUser()
    })
    return {
      ...toRefs(state),
      imgSaveToUrl
    }
  }
})
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }
 .editor-slide-upload {
  .el-upload--picture-card {
    width: 100%;
  }
}
  .uploadImag{
      width: 200px;
      height: 100px;
      background-color: green;
  }
}
</style>


import { defineComponent, onBeforeMount, reactive, toRefs, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'
import { allRoles, updateStatus, addManage } from '@/apis/user'
import { ElMessage } from 'element-plus'
interface Role {
    username: string|number
    password: string
    realname: string
    userdept: string|number
    userrole: string|number
    faceimg: any
}
const defaultRole: Role = {
  username: '',
  password: '',
  realname: '我的',
  userdept: '',
  userrole: '',
  faceimg: ''
}

export default defineComponent({
  setup() {
    const form = document.getElementById('pic')
    const imageFrontUrl = ref('')
    const { ctx } = getCurrentInstance() as any
    const router = useRouter()
    const state = reactive({
      rolesList: [] as any,
      dialogVisible: false,
      dialogType: 'new',
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      role: Object.assign({}, defaultRole),
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
      },
      addManage: async() => {
        console.log(form)
        await addManage(state.role).then((res: any) => {
          console.log(res)
        })
      },
      handProgress: async(event: any) => {
        console.log(event)
      },
      getAllUser: async() => {
        await allRoles().then((res) => {
          state.rolesList = res?.data
        })
      },
      handleUpdate: (scope: any) => {
        const { row } = scope
        updateStatus({ eid: row.eid === 0 ? 1 : 0, rid: row.rid }).then((res: any) => {
          ctx.$message({
            type: 'success',
            message: res?.msg
          })
          state.getAllUser()
        })
      },
      checkUser: (scope: any) => {
        console.log(scope)
        router.push({
          path: 'user'
        })
      },
      changeState: (scope: any) => {
        console.log(scope)
      },
      handleSuccess: () => {
        state.dialogVisible = false
        state.role = {
          username: '',
          password: '',
          realname: '',
          userdept: '',
          userrole: '',
          faceimg: ''
        }
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imgSaveToUrl = (file: any) => {
      console.log(file)
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
      }
    }
    // const getServiceRoutes = () => {
    //   getRoutes().then((res) => {
    //     state.serviceRoutes = res?.data.routes as any as RouteRecordRaw[]
    //     state.reshapedRoutes = getReshapeRoutes(res?.data.routes as any as RouteRecordRaw[])
    //   })
    // }
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUser()
    })
    return {
      ...toRefs(state),
      imgSaveToUrl
    }
  }
})
